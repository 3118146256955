import { useQuery } from "@tanstack/react-query";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { orderBy } from "lodash";
import { getBroadcasts } from "../../../api/broadcast";
import { NotificationCountTooltip } from "../../../components/notification-count-tooltip";
import { LoadingView } from "../../../components/loading-view";
import { formatDate, formatNumber } from "../../../utils/formatters";

export const BroadcastHistory = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery({
    queryKey: ["broadcasts"],
    queryFn: getBroadcasts,
  });

  const items = orderBy(data?.items, "createdAt", "desc");

  if (isLoading) {
    return <LoadingView paddingTop={8} />;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow style={{ backgroundColor: "#FAFAFA" }}>
            <TableCell>Area</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Message</TableCell>
            <TableCell>Author</TableCell>
            <TableCell>Sent</TableCell>
            <TableCell>Notifications</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row) => {
            const createdAt = new Date(row.createdAt * 1000);

            return (
              <TableRow
                key={row.id}
                hover
                onClick={() => navigate(`/broadcasts/${row.id}`)}
              >
                <TableCell>
                  <Typography variant="body2" fontWeight="bold">
                    {row.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{row.title}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" whiteSpace="pre-line">
                    {row.message}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {row.createdByUserName}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" noWrap>
                    {formatDate(createdAt)}
                  </Typography>
                </TableCell>
                <TableCell>
                  {row.deviceCount !== undefined && (
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <Typography variant="body2">
                        {formatNumber(row.deviceCount)}
                      </Typography>
                      <NotificationCountTooltip createdAt={createdAt} />
                    </Stack>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
